import React from "react";
import logo from "../../assets/Card/Rectangle 22 (1).png";
import CustomModal from "./CustomModal";
import { useDisclosure } from "@nextui-org/react";

const Card = ({ onCardSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Import useDisclosure hook

  const handleSelectNow = (cardName) => {
    onCardSelect(cardName);
  };

  return (
    <div className="w-72">
      {/*<!-- Component: E-commerce card --> */}
      <div className="overflow-hidden rounded bg-white text-slate-500 shadow-md shadow-slate-200">
        {/* open modal */}
        <figure onClick={onOpen}>
          <img
            onClick={() => handleSelectNow("Greek breakfast")}
            src={logo}
            alt="cardimage"
            className="aspect-video w-full"
          />
        </figure>
        {/*  <!-- Body--> */}
      </div>
      <CustomModal isOpen={isOpen} onClose={onClose} /> {/* Pass isOpen and onClose */}
    </div>
  );
};

export default Card;
