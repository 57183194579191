import React from "react";

const Header = () => {
  return (
    <div className=" py-10 px-10">
      <div>
        <h1 className="text-4xl font-bold text-center">Find your perfect land</h1>
      </div>
    
    </div>
  );
};

export default Header;
