import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";

const CustomModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent className=" bg-slate-100">
        <>
          <ModalHeader className="flex flex-col gap-1">
            Greek breakfast
          </ModalHeader>
          <ModalBody>
            <h1>₹ 5.4 Lakhs /Sq Ft</h1>
            <p  className=" flex flex-col text-slate-400">
              Plot Area
              <span className="  text-black">4,890 sqft</span>
            </p>
            {/* Add more content if needed */}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onClick={onClose}>
              Close
            </Button>
            {/* Add more buttons or actions if needed */}
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
