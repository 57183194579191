import logo from './logo.svg';
import './App.css';
import Navbar from './component/Navbar/Navbar';
import Header from './component/Header/Header';
import CardSection from './component/CardSection/CardSection';

function App() {
  return (
   <div className='' >

    <Navbar></Navbar>
    <Header></Header>

    <CardSection></CardSection>

    
   </div>

  );
}

export default App;
