import React, { useState } from "react";
import Card from "../Card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CardSection = () => {
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelect = (cardName) => {
    setSelectedCards([...selectedCards, cardName]); // Add the selected card name to the list
  };
  const handleRemoveCard = (index) => {
    const updatedCards = [...selectedCards];
    updatedCards.splice(index, 1);
    setSelectedCards(updatedCards);
  };
  return (
    <div>
      <section>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-9">
              <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                <Card onCardSelect={handleCardSelect}></Card>
                <Card onCardSelect={handleCardSelect}></Card>
                <Card onCardSelect={handleCardSelect}></Card>
                <Card onCardSelect={handleCardSelect}></Card>
                <Card onCardSelect={handleCardSelect}></Card>
                <Card onCardSelect={handleCardSelect}></Card>
              </div>
            </div>
            <div className="col-span-4 lg:col-span-3   ">
              <div className=" shadow-md p-2 rounded-lg border  ">
                {/* //selected card list  */}
                <ul className="list-none p-0 m-0 space-y-2">
                  {selectedCards.map((card, index) => (
                    <li
                      key={index}
                      className="bg-slate-100 rounded-lg p-4 flex justify-between items-center"
                    >
                      <span>{card}</span>
                      <button onClick={() => handleRemoveCard(index)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </li>
                  ))}
                </ul>

                <div className="">
                  <div className="sm:col-span-1 md:col-span-1 lg:col-span-1">
                    <label htmlFor="firstName" className="block mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="John"
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="sm:col-span-1 md:col-span-1 lg:col-span-1">
                    <label htmlFor="lastName" className="block mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Doe"
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="sm:col-span-1 md:col-span-1 lg:col-span-1">
                    <label htmlFor="phone" className="block mb-1">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="+255 757 557 557"
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="sm:col-span-1 md:col-span-1 lg:col-span-1">
                    <label htmlFor="email" className="block mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="john@doe.com"
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="mt-6">
                  <button className="w-full px-4 py-2 text-white bg-gradient-to-r from-yellow-400 to-yellow-600 bg-origin-border bg-clip-border rounded-md shadow-md hover:bg-gradient-to-r from-yellow-500 to-yellow-700 focus:bg-gradient-to-r from-yellow-600 to-yellow-800 focus-visible:outline-none">
                    Submit

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CardSection;
